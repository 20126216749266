export const ROLE_ADMIN = 'admin'

export const ROLE_EMPLOYEE = 'employee'

export const ROLE_END_USER = 'end_user'

export const STUDENT_STAKEHOLDER_TYPE_ID = 1

export const PROFESSOR_STAKEHOLDER_TYPE_ID = 2

export const ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID = 3

export const EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID = 4

export const CREATED = 1

export const APPROVED = 2

export const PARTIALLY_APPROVED = 3

export const REJECTED = 4

export const CANCELED_BY_REQUESTER = 5

