import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Input,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { getInterpreters, getScedulesList } from "store/shared/actions";

import { acceptRequest } from "store/requests/actions";
import { ScheduleConfirm } from "./NewRequestTabs/components/ScheduleConfirm";
import CustomSelect from "./NewRequestTabs/components/MutiSelect";
import FormObserver from "./NewRequestTabs/components/FormObserver";
import { toast } from "react-toastify";

class RequestConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestId: "",
      selected: [],
      interpreters: [],
      prevPartialSchedules: [],
      reason: "",
      reason: "",
      allChecked: true,
      showReasonField: false,
      allSchedules: [],
      schedules: [],
    };
  }

  componentDidMount() {
    const { onGetSchedulesList, onGetInterpreters } = this.props;
    const requestId = this.props.location.pathname.split("/")[2];
    this.setState({ ...this.state, requestId });
    onGetSchedulesList(requestId);
    onGetInterpreters(requestId);
    this.setState({ prevPartialSchedules: this.props.partialSchedules });
  }
  updateSelectedSchedules = partialSchedules => {
    const allSelected = [];
    partialSchedules.forEach(partialSchedule => {
      partialSchedule.scheduleConfirmations.forEach(scheduleConfirmation => {
        allSelected.push(scheduleConfirmation);
      });
    });
    this.setState({ selected: allSelected });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.interpreters !== this.props.interpreters) {
      const interpreterOptions = this.props.interpreters.map(interpreter => ({
        label: interpreter.name,
        value: interpreter.id,
      }));

      this.setState({ ...this.state, interpreters: interpreterOptions });
    }

    if (this.state.prevPartialSchedules !== this.props.partialSchedules) {
      this.setState({
        ...this.state,
        prevPartialSchedules: this.props.partialSchedules,
      });
      for (let i = 0; i < this.props.partialSchedules.length; i++) {
        this.updateSelectedSchedules(this.props.partialSchedules);
      }
    }
  }

  handleReasonChange = event => {
    this.setState({ ...this.state, reason: event.target.value });
  };

  handleCheckboxChange = (event, scheduleConfirmation, selected) => {
    const { checked } = event.target;
    const isScheduleSelected = selected.find(
      schedule =>
        schedule.name === scheduleConfirmation.name &&
        schedule.attendanceScheduleId ===
          scheduleConfirmation.attendanceScheduleId
    );

    const selectedItems = [];
    if (checked) {
      if (isScheduleSelected) {
        selectedItems = selected;
      } else {
        selectedItems = [...selected, scheduleConfirmation];
      }
    } else {
      if (isScheduleSelected) {
        selectedItems = selected.filter(
          schedule => schedule.name !== scheduleConfirmation.name
        );
      } else {
        selectedItems = selected;
      }
    }

    this.setState({
      ...this.state,
      selected: selectedItems,
    });

    let totalConfirmations = 0;
    this.props.partialSchedules.forEach(partialSchedule => {
      totalConfirmations =
        totalConfirmations + partialSchedule.scheduleConfirmations.length;
    });
    if (selectedItems.length >= totalConfirmations) {
      this.setState({ ...this.state, allChecked: true });
    } else {
      this.setState({ ...this.state, allChecked: false });
    }
  };

  handleSubmit() {
    const { selected, reason, requestId } = this.state;

    if (selected.length === 0) {
      toast.error("Selecione pelo menos um compromisso");
      return;
    }

    const schedulesWithoutInterpreters = selected.filter(schedule => {
      return schedule.interpreters.length < 1;
    });

    if (schedulesWithoutInterpreters.length > 1) {
      toast.error("Selecione pelo menos um intérprete para cada compromisso");
      return;
    }

    const { onAcceptRequest } = this.props;
    const data = {
      schedules: selected,
      reason,
      requestId,
    };
    onAcceptRequest(data, this.props.history);
  }

  handleInterpreterSelect = (scheduleConfirmation, interpreters) => {
    const selected = this.state.selected;
    const selectedSchedule = selected.find(
      schedule => schedule.name === scheduleConfirmation.name
    );
    selectedSchedule.interpreters = interpreters;
    console.log(selectedSchedule);
    this.setState({ ...this.state, selected });
  };

  copyInterpretersToAll = interpreters => {
    console.log(interpreters);
    this.props.partialSchedules.forEach(partialSchedule => {
      partialSchedule.scheduleConfirmations.forEach(scheduleConfirmation => {
        this.handleInterpreterSelect(scheduleConfirmation, interpreters);
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Nupi" breadcrumbItem="Solicitação" />
            <Row>
              <Col lg="12">
                {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null}
              </Col>
            </Row>

            <h4 className="card-title mb-4">
              Aceitar solicitação parcialmente
            </h4>
            <FormGroup>
              <h6>Agenda solicitada</h6>
              {this.props.partialSchedules.map((preSchedule, index) => {
                const schedule = preSchedule.schedule;
                return (
                  <Card key={index}>
                    <CardBody>
                      <ScheduleConfirm schedule={schedule} />
                      <Formik
                        key={index}
                        enableReinitialize
                        initialValues={{
                          interpreters: [],
                          schedules: this.state?.schedules || [],
                        }}
                        onSubmit={values => {
                          this.props.onAcceptRequest(
                            {
                              attendanceRequestId: this.state.requestId,
                              interpreterId: values.interpreter,
                            },
                            this.props.history
                          );
                        }}
                      >
                        {_ => (
                          <Form className="form-vertical">
                            <div className="my-3">
                              <Label
                                for="schedules-form"
                                className="form-label"
                              >
                                Compromissos
                              </Label>
                              {preSchedule.scheduleConfirmations.map(
                                (scheduleConfirmation, index) => {
                                  scheduleConfirmation.interpreters = [];
                                  const name = `schedule-${index}${schedule.id}`;
                                  scheduleConfirmation.name = name;
                                  const checked =
                                    this.state.selected.find(
                                      schedule =>
                                        schedule.name ===
                                        scheduleConfirmation.name
                                    ) || false;
                                  return (
                                    <Col key={index}>
                                      <Label className="mt-4 mr-4 pr-4">
                                        <Field
                                          type="checkbox"
                                          name={name}
                                          checked={checked}
                                          style={{ marginRight: "10px" }}
                                          onChange={e =>
                                            this.handleCheckboxChange(
                                              e,
                                              scheduleConfirmation,
                                              this.state.selected
                                            )
                                          }
                                        />
                                        {format(
                                          new Date(scheduleConfirmation.date),
                                          "dd/MM/yyyy HH:mm"
                                        )}{" "}
                                        até{" "}
                                        {format(
                                          new Date(
                                            scheduleConfirmation.dateEnd
                                          ),
                                          "dd/MM/yyyy HH:mm"
                                        )}
                                      </Label>
                                      {checked && (
                                        <Formik
                                          key={index}
                                          enableReinitialize
                                          initialValues={{
                                            showCopyButton: false,
                                            interpreters: [],
                                            schedules:
                                              (this.state &&
                                                this.state.schedules) ||
                                              [],
                                          }}
                                          onSubmit={values => {
                                            this.props.onAcceptRequest(
                                              {
                                                attendanceRequestId:
                                                  this.state.requestId,
                                                interpreterId:
                                                  values.interpreter,
                                              },
                                              this.props.history
                                            );
                                          }}
                                        >
                                          {formikProps => (
                                            <>
                                              <Field
                                                name="interpreters"
                                                options={
                                                  this.state.interpreters
                                                }
                                                enableReinitialize
                                                component={CustomSelect}
                                                placeholder="Selecione um ou mais intérpretes"
                                                isMulti
                                                className="mb-2"
                                                onFieldChange={() => {
                                                  formikProps.setFieldValue(
                                                    "showCopyButton",
                                                    true
                                                  );
                                                }}
                                                onEmpty={() => {
                                                  formikProps.setFieldValue(
                                                    "showCopyButton",
                                                    false
                                                  );
                                                }}
                                              />
                                              {formikProps.values
                                                .showCopyButton && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <Button
                                                    type="button"
                                                    color="primary"
                                                    onClick={() =>
                                                      this.copyInterpretersToAll(
                                                        formikProps.values
                                                          .interpreters
                                                      )
                                                    }
                                                    disabled={
                                                      this.props.loading
                                                    }
                                                  >
                                                    {this.props.loading
                                                      ? "Aguarde..."
                                                      : "Copiar seleção de intérpretes"}
                                                  </Button>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </Formik>
                                      )}
                                    </Col>
                                  );
                                }
                              )}
                              <ErrorMessage
                                name="interpreter"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                );
              })}
            </FormGroup>
            {!this.state.allChecked && (
              <Card>
                <CardBody>
                  <div>
                    <Label for="reason">Motivo do aceite parcial</Label>
                    <Input
                      type="text"
                      className="form-input mt-4 d-flex flex-row-reverse"
                      placeholder="Informe o motivo pelo qual não foi possível atender à esta solicitação integralmente"
                      name="reason"
                      onChange={e => this.handleReasonChange(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            )}
            <div className="text-center mt-4 d-flex flex-row-reverse">
              <Button
                size="lg"
                type="submit"
                color="success"
                onClick={e => this.handleSubmit(e)}
                disabled={this.props.loading}
              >
                {this.props.loading ? "Aguarde..." : "Aceitar solicitação"}
              </Button>
              <button
                className="d-none"
                type="submit"
                id="submit-event-information"
              ></button>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

RequestConfirm.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onGetSchedulesList: PropTypes.func,
  onGetInterpreters: PropTypes.func,
  onAcceptRequest: PropTypes.func,
};

const mapStateToProps = ({ shared, Request }) => ({
  error: shared.error || Request.error,
  success: shared.success || Request.success,
  partialSchedules: shared.partialSchedules,
  interpreters: shared.interpreters,
  loading: Request.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetInterpreters: requestId => dispatch(getInterpreters(requestId)),
  onGetSchedulesList: id => dispatch(getScedulesList(id)),
  onAcceptRequest: (data, history) => dispatch(acceptRequest(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestConfirm)
);
