import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  UncontrolledTooltip,
  Table,
  Input,

} from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'
import { format } from 'date-fns';
import {
  getCanceledRequests,
  exportCanceledRequests
} from 'store/reporting/actions'

import { map } from 'lodash'

class CanceledRequests extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      attendanceRequests: [],
      name: '',
      total: 0,
      pages: [],
      sectors: [],
      currentPage: 1,
      attendanceRequest: '',
      startDate: "",
      endDate: "",
    }

  }

  componentDidMount() {
    const { attendanceRequests, onGetCanceledRequests } = this.props
    if (attendanceRequests && !attendanceRequests.length) {
      onGetCanceledRequests(1, this.state.startDate, this.state.endDate)
      this.setState({
        attendanceRequests
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { attendanceRequests, onGetCanceledRequests, totalCount, page: currentPage } = this.props
    if (prevProps !== this.props) {
      this.setState({ attendanceRequests: {}, isEdit: false })
      const totalPages = Math.ceil(totalCount / 10)
      const arrayPages = []
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i)
      }
      this.setState({
        total: totalCount,
        pages: arrayPages,
        currentPage,
      })
    }

    if (prevProps.page !== this.props.page) {
      onGetCanceledRequests(this.state.currentPage, this.state.startDate, this.state.endDate);
    }
    if (prevState.currentPage !== this.state.currentPage) {
      onGetCanceledRequests(this.state.currentPage, this.state.startDate, this.state.endDate)
    }
    if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
      onGetCanceledRequests(this.state.currentPage, this.state.startDate, this.state.endDate)
    }
  }

  render() {
    document.title = 'Solicitações Rejeitadas | Atendimento NUPI'
    const { attendanceRequests, totalCount, history, sectors } = this.props
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='Relatórios' breadcrumbItem='Solicitações rejeitadas' />
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <Row>
                          <Col md="5" className="align-items-baseline">
                            <Label for="startDate" className="form-label">Data inicial</Label>
                            <div>
                              <Input type="date" name="startDate" id="startDate" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ startDate: e.target.value })} />
                            </div>
                          </Col>
                          <Col md="5" className="align-items-baseline">
                            <Label for="endDate" className="form-label">Data final</Label>
                            <div>
                              <Input type="date" name="endDate" id="endDate" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ endDate: e.target.value })} />
                            </div>
                          </Col>
                          <Col md="2" className="d-flex flex-column justify-content-between align-items-center">
                            <i ></i>
                            <Button color="success" aria-label="Exportar planilha" disabled={this.props.loading} style={{ margin: '0 auto' }} onClick={() => this.props.onExportCanceledRequests(this.state.page, this.state.startDate, this.state.endDate)}>
                              {this.props.loading ? 'Aguarde...' : 'Exportar planilha'}
                            </Button>
                          </Col>
                        </Row>
                        <Table responsive>
                        </Table>
                      </Col>
                    </Row>

                    <Table responsive>
                      <thead className='text-center border-bottom'>
                        <tr>
                          <th>Nome</th>
                          <th>Setor</th>
                          <th>Solicitação</th>
                          <th>Data</th>
                          <th>Rejeitada em</th>
                          <th>Motivo</th>
                        </tr>
                      </thead>
                      <tbody className='text-center'>
                        {attendanceRequests.map(attendanceRequest => (
                          <tr key={attendanceRequest.id} tabIndex="0">
                            <td className='px-0 ' aria-label={'Nome do solicitante' + attendanceRequest.creator.name}>
                              {attendanceRequest.creator.name}
                            </td>
                            <td className='px-0' aria-label={'Setor da solicitação' + attendanceRequest.sector.sector}>
                              {attendanceRequest.sector.sector}
                            </td>
                            <td className='px-0' aria-label={'Nome do evento' + attendanceRequest.eventName}>
                              {attendanceRequest.eventName}
                            </td>
                            <td className='px-0' aria-label={'Data de criação' + format(new Date(attendanceRequest.createdAt), 'dd/MM/yyyy HH:mm:ss')}>
                              {format(new Date(attendanceRequest.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                            </td>
                            <td className='px-0' aria-label={'Data de rejeição' + format(new Date(attendanceRequest.updatedAt), 'dd/MM/yyyy HH:mm:ss')}>
                              {format(new Date(attendanceRequest.updatedAt), 'dd/MM/yyyy HH:mm:ss')}
                            </td>
                            <td className='px-0' aria-label={'Perfil do usuário' + attendanceRequest.rejectedReason}>
                              {attendanceRequest.rejectedReason}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className='d-flex flex-row-reverse bd-highlight'>
                      <div className='p-2 bd-highlight'>
                        {totalCount > 10 ? this.state.pages.map(page => (
                          <button
                            disabled={page === this.state.currentPage}
                            key={page}
                            onClick={() => {
                              this.setState({ currentPage: page })
                            }}
                            className='btn btn-primary mx-1 rounded-circle'
                          >
                            {page}
                          </button>
                        )) : null}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div >
      </React.Fragment >
    )
  }
}

CanceledRequests.propTypes = {
  errorMessage: PropTypes.any,
  attendanceRequests: PropTypes.array,
  onGetCanceledRequests: PropTypes.func,
  onExportCanceledRequests: PropTypes.func,
}

const mapStateToProps = (state) => ({
  errorMessage: state.canceledRequests.error,
  attendanceRequests: state.canceledRequests.requests,
  currentPage: state.canceledRequests.page,
  totalCount: state.canceledRequests.totalCount,
  pageCount: state.canceledRequests.pageCount,
  page: state.canceledRequests.page,
  loading: state.canceledRequests.loading
})


const mapDispatchToProps = dispatch => ({
  onGetCanceledRequests: (page = 1, startDate, endDate) =>dispatch(getCanceledRequests(page, startDate, endDate)),
  onExportCanceledRequests: (page = 1, startDate, endDate) =>dispatch(exportCanceledRequests(page, startDate, endDate)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CanceledRequests)
)
