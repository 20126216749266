import React from "react";
import { Col, Form, Input, Label, Row, FormGroup } from "reactstrap";
import { ScheduleConfirm } from "./components/ScheduleConfirm";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { submitRequest } from "../../../store/actions";

class ConfirmRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: "",
      activity: "",
      sectorId: "",
      sectorName: "",
      format: "",
      nationalAnthems: "",
      musicalPresentations: false,
      musicalDownloadLink: "",
      schedules: [],
      otherSector: "",
      otherActivity: "",
      songLinks: "",
      additionalRemarks: "",
      requestStatusId: "",
      rejectedReason: null,
      reasonAcceptPartially: null,
      address: "",
    };
  }

  renderStatus = status => {
    switch (status) {
      case 1:
        return "Aguardando análise";
      case 2:
        return "Aceita integralmente";
      case 3:
        return "Aceita parcialmente";
      case 4:
        return "Rejeitada";
      case 5:
        return "Cancelada pelo solicitante";
    }
  };

  componentDidMount() {
    const {
      eventName,
      activity,
      sectorId,
      format,
      nationalAnthems,
      musicalPresentations,
      musicalDownloadLink,
      schedules,
      otherActivity,
      otherSector,
      songLinks,
      additionalRemarks,
      requestStatusId,
      rejectedReason,
      reasonAcceptPartially,
      address,
      sectorName,
      sector,
    } = this.props.data;
    this.setState({
      eventName,
      activity,
      sectorId,
      format,
      nationalAnthems,
      musicalPresentations,
      musicalDownloadLink,
      schedules,
      otherActivity,
      otherSector,
      songLinks,
      additionalRemarks,
      requestStatusId,
      rejectedReason,
      reasonAcceptPartially,
      address,
      sectorName,
    });

    if (sector) {
      this.setState({ sectorName: sector.sector });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps != this.props) {
      const {
        eventName,
        activity,
        sectorId,
        format,
        nationalAnthems,
        musicalPresentations,
        musicalDownloadLink,
        schedules,
        otherActivity,
        otherSector,
        songLinks,
        additionalRemarks,
        requestStatusId,
        rejectedReason,
        reasonAcceptPartially,
        address,
        sectorName,
        sector,
      } = this.props.data;
      this.setState({
        eventName,
        activity,
        sectorId,
        format,
        nationalAnthems,
        musicalPresentations,
        musicalDownloadLink,
        schedules,
        otherActivity,
        otherSector,
        songLinks,
        additionalRemarks,
        requestStatusId,
        rejectedReason,
        reasonAcceptPartially,
        address,
        sectorName,
      });

      if (sector) {
        this.setState({ sectorName: sector.sector });
      }
    }
  }
  render() {
    const confirmationStyle = {
      border: '1px solid #ccc',
      padding: '10px',
      marginTop: '10px',
      background: '#eff2f7',
      borderRadius: '5px',
    };
    return (
      <Form onSubmit={() => { }}>
        <FormGroup>
          {this.state.confirmation ? (
            <div tabIndex={0} >
              <Label for="event-name" aria-label={`Valor confirmado: ${this.state.eventName}`}>Nome (evento ou componente curricular)</Label>
              <div style={confirmationStyle} aria-labelledby="event-name">
                {this.state.eventName}
              </div>
            </div>
          ) : (
            <>
              <Label for="event-name">Nome (evento ou componente curricular)</Label>
              <Input
                type="text"
                className="form-control"
                id="event-name"
                value={this.state.eventName}
                disabled
              />
            </>
          )}
        </FormGroup>
        <Row>
          <Col lg="6">
            <FormGroup>
              <div tabIndex={0}>
                <Label for="activityy">Atividade</Label>
                <div style={confirmationStyle}>
                  {this.state.activity === "Outra"
                    ? this.state.otherActivity
                    : this.state.activity}
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <div tabIndex={0}>
                <Label for="sector">Setor</Label>
                <div style={confirmationStyle}>
                  {this.state.sectorName}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>

              <div tabIndex={0}>
                <Label for="format">Formato</Label>
                <div style={confirmationStyle}>
                  {this.state.format === "presential" ? "Presencial" : "Remoto"}
                </div>
              </div>

            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>

              <div tabIndex={0}>
                <Label for="nationalAnthems">Hino nacional</Label>
                <div style={confirmationStyle}>
                  {this.state.nationalAnthems === "true" || this.state.nationalAnthems === true ? "Sim" : "Não"}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <div tabIndex={0}>
              <Label for="event-namee">
                {this.state.format === "presential"
                  ? "Local da atividade"
                  : "Link da atividade"}
              </Label>
              <div style={confirmationStyle}>
                {this.state.address}
              </div>
            </div>
          </FormGroup>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <div tabIndex={0}>
                <Label for="musicalPresentations">Músicas</Label>
                <div style={confirmationStyle}>
                  {this.state.musicalPresentations === "true" ||
                    this.state.musicalPresentations === true
                    ? "Sim"
                    : "Não"}
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col
            lg="6"
            className={
              this.state.musicalPresentations === "false" ||
                this.state.musicalPresentations === false
                ? "d-none"
                : ""
            }
          >
            <FormGroup>
              <div tabIndex={0}>
                <Label for="songLinks">Link das músicas</Label>
                <div style={confirmationStyle}>
                  {this.state.songLinks || this.state.musicalDownloadLink || 'Nenhum link disponível'}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <div tabIndex={0}>
                <Label for="additionalRemarks">Observações adicionais</Label>
                <div style={confirmationStyle}>
                  {this.state.additionalRemarks}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <div tabIndex={0}>
                <Label for="requestStatus">Status</Label>
                <div style={confirmationStyle}>
                  {this.renderStatus(this.state.requestStatusId)}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        {this.props.data.creator && (
          <>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <div tabIndex={0}>
                    <Label for="name">Nome do solicitante</Label>
                    <div style={confirmationStyle}>
                      {this.props.data.creator.name || ''}
                    </div>
                  </div>

                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <div tabIndex={0}>
                    <Label for="email">Email do solicitante</Label>
                    <div style={confirmationStyle}>
                      {this.props.data.creator.email || ''}
                    </div>
                  </div>

                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <div tabIndex={0}>
                    <Label for="phone">Telefone do solicitante</Label>
                    <div style={confirmationStyle}>
                      {this.props.data.creator.phone || "N/A"}
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <FormGroup>
          <h6>Agenda solicitada</h6>
          {this.state.schedules.map((schedule, index) => {
            return <ScheduleConfirm schedule={schedule} key={index} />;
          })}
        </FormGroup>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { error, loading, data } = state.Request;
  return { error, loading, data };
};

export default withRouter(
  connect(mapStateToProps, { submitRequest })(ConfirmRequest)
);
