import {
  GET_REQUESTS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAIL, 
  API_ERROR,
  GET_REQUESTS_LOADING,
  GET_EXPORT_SUCCESS

} from "./actionTypes"

const INIT_STATE = {
  requests: [],
  pageCount: 0,
  currentPage: 0,
  totalCount: 0,
  page: 1,
  error: "",
  loading: false,
}

const reporting = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REQUESTS_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }
    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        error: "",
        requests: action.payload.attendance,
        currentPage: action.payload.page,
        pageCount: action.payload.pageCount,
        totalCount: action.payload.totalCount,
        page: action.payload.page,
        loading: false
      }
    case GET_REQUESTS_FAIL: 
      return {
        ...state,
        error: action.payload,
        loading: false
      }
      case GET_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      }
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export default reporting
