// import { api } from '../../../services/api'
// import { processErrorMessage } from '../../../helpers/errors'
import { toast } from "react-toastify";
import { api } from "services/api";
import { useHistory } from "react-router-dom";
const history = useHistory;

import {
  SEND_EVENT_INFORMATION,
  SEND_SCHEDULES,
  SEND_DATA_TO_CONFIRM,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_ERROR,
  SEND_REQUEST_DATA,
  GET_REQUESTS_DATA,
  RESET_STATE,
  GET_REQUEST_DATA,
  DELETE_REQUEST_RESPONSE,
  TOGGLE_SAVE_REQUEST,
  TOGGLE_LOADING,
  GET_REQUEST_MEEETING_DATA,
  GET_MEETING_DETAILS,
  CLOSE_MODAL,
} from "./actionTypes";

export const sendEventInformation = data => dispatch => {
  dispatch({ type: SEND_EVENT_INFORMATION, payload: data });
};

export const sendSchedules = data => dispatch => {
  dispatch({ type: SEND_SCHEDULES, payload: data });
};

export const sendDataToConfirmRequest = data => dispatch => {
  dispatch({ type: SEND_DATA_TO_CONFIRM, payload: data });
};

export const sendRequest = (data, history) => dispatch => {
  api
    .post("/attendance_requests", data, { withCredentials: true })
    .then(data => {
      if (data && data.status == 200) {
        dispatch({ type: SUBMIT_REQUEST_SUCCESS, payload: data });
        toast.success("Solicitação registrada com sucesso!");
        dispatch(getRequests("", 1));
        history.push("/my-requests");
      } else {
        toast.error(
          "Falha ao registrar solicitação, contate o administrador do sistema!"
        );
        dispatch({ type: SUBMIT_REQUEST_ERROR, payload: "Falha" });
      }
    })
    .catch(function (error) {
      toast.error(
        "Falha ao registrar solicitação, contate o administrador do sistema!"
      );
      dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
    });
};

export const submitRequest = data => dispatch => {
  dispatch({ type: SUBMIT_REQUEST_SUCCESS, payload: data });
};

export const getRequests = (activity, page) => dispatch => {
  api
    .get(`/attendance_requests?activity=${activity}&page=${page}`, {
      withCredentials: true,
    })
    .then(requests => {
      dispatch({ type: GET_REQUESTS_DATA, payload: requests.data });
    })
    .catch(function (error) {
      toast.error("Falha ao carregar requisições!");
      dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
    });
};

export const getAllRequests = (activity, page) => dispatch => {
  api
    .get(`attendance_requests/all?activity=${activity}&page=${page}`, {
      withCredentials: true,
    })
    .then(requests => {
      dispatch({ type: GET_REQUESTS_DATA, payload: requests.data });
    })
    .catch(function (error) {
      if (error.response) {
        toast.warning(error.response.data.errors[0].message);
      }
      dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
    });
};

export const getSelectedMeetingDetails = id => dispatch => {
  api
    .get(`/schedules_confirmation/${id}/interpreters`, {
      withCredentials: true,
    })
    .then(response => {
      dispatch({ type: GET_MEETING_DETAILS, payload: response.data });
    })
    .catch(function (error) {
      dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
    });
};

export const getRequestMeetings = (id, start, end) => dispatch => {
  api
    .get(`/attendance_requests/${id}/meetings?start=${start}&end=${end}`, {
      withCredentials: true,
    })
    .then(response => {
      dispatch({ type: GET_REQUEST_MEEETING_DATA, payload: response.data });
    })
    .catch(function (error) {
      dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
    });
};

export const getRequestById = id => dispatch => {
  api
    .get(`/attendance_requests/${id}`, { withCredentials: true })
    .then(response => {
      dispatch({ type: GET_REQUEST_DATA, payload: response.data });
    });
};

export const cancelRequest = (id, history) => dispatch => {
  dispatch({ type: TOGGLE_LOADING, payload: true });
  api
    .delete(`/attendance_requests/${id}`, { withCredentials: true })
    .then(response => {
      dispatch({ type: DELETE_REQUEST_RESPONSE, payload: response.status });
      toast.success(
        "Solicitação cancelada com sucesso",
        "Você receberá um e-mail com a confirmação deste cancelamento!"
      );
      dispatch(getRequests("", 1));
      dispatch({ type: TOGGLE_LOADING, payload: true });
      history.push("/my-requests");
    })
    .catch(function (error) {
      if (error.response) {
        toast.error(error.response.data.errors[0].message);
      }
      dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
      dispatch({ type: TOGGLE_LOADING, payload: true });
    });
};

export const acceptRequest = (data, history) => dispatch => {
  dispatch({ type: TOGGLE_LOADING, payload: true });

  api
    .post('/schedules_confirmation/"/accept_schedule"', data, {
      withCredentials: true,
    })
    .then(response => {
      if (response && response.status == 200) {
        toast.success("Solicitação aceita com sucesso!");
        history.push("/my-requests");
      } else {
        toast.error(
          "Falha ao aceitar solicitação, contate o administrador do sistema!"
        );
      }
    })
    .catch(function (error) {
      dispatch({
        type: SUBMIT_REQUEST_ERROR,
        payload: error.response.data.errors[0].message,
      });
    })
    .finally(() => {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    });
};
export const submitError = error => dispatch => {
  dispatch({ type: SUBMIT_REQUEST_ERROR, payload: error });
};

export const rejectRequest = (id, data, history) => dispatch => {
  dispatch({ type: TOGGLE_LOADING, payload: true });
  api
    .delete(
      `/attendance_requests/reject/${id}`,
      { data },
      { withCredentials: true }
    )
    .then(response => {
      if (response && response.status == 200) {
        dispatch({ type: SUBMIT_REQUEST_SUCCESS, payload: response.status });
        history.push("/my-requests");
      } else {
        toast.error("Falha ao rejeitar solicitação!");
      }
    })
    .catch(function (error) {
      dispatch({
        type: SUBMIT_REQUEST_ERROR,
        payload: error.response.data.errors[0].message,
      });
    })
    .finally(() => {
      dispatch({ type: TOGGLE_LOADING, payload: false });
    });
};

export const toggleSaveRequest = () => ({
  type: TOGGLE_SAVE_REQUEST,
});

export const onCloseModal = () => ({
  type: CLOSE_MODAL,
});
