import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import moment from 'moment';
import 'moment/locale/pt-br';

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
//css
import "@fullcalendar/bootstrap/main.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getMeetingDetailsAdmin,
  closeModal,
  getEventsCalendar,
} from "../../store/actions";
import { date } from "yup";

class Appointments extends Component {
  
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();
    this.state = {
      interpreters: [],
      name: "",
      total: 0,
      flag: 0,
      pages: [],
      currentPage: 1,
      interpreter: "",
      modal: false,
      event: {},
    };
    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.colorsForEvents = {};
    this.currentDate = moment();
  }

  handleDatesSet = info => {
    const view = info.view;
    const dateIntervalStart = view.activeStart;
    const dateIntervalEnd = view.activeEnd;
    const { onGetEvents } = this.props;
    onGetEvents(dateIntervalStart, dateIntervalEnd);
  };

  updateCalendarView() {
    const calendarApi = this.calendarComponentRef.current.getApi();
    const view = calendarApi.view;
    this.handleDatesSet({ view });
  }

  toggle(selectedMeeting) {
    const eventId = selectedMeeting.id;
    const userId = selectedMeeting.extendedProps
      ? selectedMeeting.extendedProps.interpreter.id
      : undefined;
    if (this.props.event == null) {
      const { onGetMeetingDetails } = this.props;
      onGetMeetingDetails(eventId, userId);
    } else {
      const { onCloseModal } = this.props;
      onCloseModal();
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
        selectedInterpreterId: tab,
      });
    }
    this.updateCalendarView(tab);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { event } = this.props;
    const { modal } = this.state;
    if (prevState.modal !== modal && !modal && !isEmpty(event)) {
      setTimeout(() => {
        this.setState({ event: {} });
      }, 500);
    }
    if (prevState.event !== event && !isEmpty(event)) {
      this.setState({ event: event });
    }
  }

  handleEventClick = arg => {
    const selectedMeeting = arg.event;
    this.toggle(selectedMeeting);
  };

  getFirstAndLastName(nomeCompleto) {
    const partesDoNome = nomeCompleto.split(" ");
    const primeiroNome = partesDoNome[0];
    const ultimoNome = partesDoNome[partesDoNome.length - 1];
    return primeiroNome + " " + ultimoNome;
  }

  generateRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getColorForEvent(event) {
    if (this.colorsForEvents[event.title]) {
      return this.colorsForEvents[event.title];
    }
    const randomColor = this.generateRandomColor();

    this.colorsForEvents[event.title] = randomColor;

    return randomColor;
  }
  onViewTypeChange = (newViewType) => {
    this.setState({ viewType: newViewType });
  };

  generateButtonText = (buttonName) => {
    let date = this.currentDate.clone();
    let buttonText = "";
    moment.locale('pt-br')
    if (buttonName === "prev") {
      buttonText = "Clendário exibindo:" + date.format("MMMM YYYY");
    } 
    return buttonText;
  };

  render() {
    
    document.title = "Compromissos dos interpretes | Atendimento NUPI";
    const { event } = this.state;
    const repeat = {
      weekly: "Semanal",
      monthly: "Mensal",
      daily: "Diário",
      "not-repeat": "Não",
    };

    const renderEventContent = (eventInfo) => {
      const event = eventInfo.event;
      const title = this.getFirstAndLastName(event.title);
      const startDate = new Date(event.startStr);
      const dayOfWeek = new Intl.DateTimeFormat("pt-BR", { weekday: "long" }).format(startDate);
      const eventColor = this.getColorForEvent(event);
      const formattedTime = startDate.toLocaleTimeString("pt-BR", { hour: "2-digit", minute: "2-digit" });
      const ariaLabel = `Evento em ${dayOfWeek}, ${startDate.getDate()} de ${startDate.toLocaleDateString("pt-BR", { month: "long" })} às ${formattedTime} do intérprete ${event.title}`;

      return (
        <div aria-label={ariaLabel} tabIndex={0} role="button" style={{ display: "flex", alignItems: "center", }}>
          <div style={{ width: "8px", height: "8px", borderRadius: "50%", backgroundColor: eventColor, marginRight: "8px", }}></div>
          {formattedTime} &nbsp; <b> {title} </b>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Atendimento"
              breadcrumbItem="Compromissos dos interpretes"
            />
            <i tabIndex="0" aria-label={this.generateButtonText("prev")}></i>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <TabContent
                      activeTab={this.state.activeTab1}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId={this.state.activeTab1}>
                        <Row>
                          <Col sm="12">
                            <Col className="col-12">
                              <FullCalendar
                                onNavigate={this.onViewTypeChange}
                                plugins={[dayGridPlugin, interactionPlugin, BootstrapTheme]}
                                themeSystem="bootstrap"
                                locale={ptBrLocale}
                                initialView="dayGridMonth"
                                dateClick={this.handleDateClick}
                                eventClick={this.handleEventClick}
                                datesSet={this.handleDatesSet}
                                events={this.props.events}
                                eventContent={renderEventContent}
                                headerToolbar={{
                                  left: "prev,next today",
                                  center: "title",
                                  right: "dayGridMonth,dayGridWeek,dayGridDay"
                                }}

                              />

                              <Modal
                                isOpen={this.props.event != null}
                                className={this.props.className}
                                id="event-modal"
                              >
                                <ModalHeader toggle={this.toggle} tag="h4" aria-level="0" role="presentation">
                                  {
                                    event.scheduleConfirmations
                                      ?.attendanceRequest?.eventName
                                  }
                                </ModalHeader>
                                <ModalBody>
                                  {event.scheduleConfirmations && (
                                    <>
                                      <Row>
                                        <Col xs={12}>
                                          <div className="mb-3 " tabIndex={-1}>
                                            <h5 aria-level="0" role="presentation" aria-label={`Tipo da atividade ${event.scheduleConfirmations.attendanceRequest.activity}`}>
                                              <b>Atividade:</b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.activity}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={`Com formato ${event.scheduleConfirmations.attendanceRequest.format ===
                                              "presential"
                                              ? "Presencial"
                                              : "Remoto"}`}>
                                              <b>Formato:</b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.format ===
                                                "presential"
                                                ? "Presencial"
                                                : "Remoto"}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={`do Setor ${event.scheduleConfirmations.attendanceRequest.sector.sector}`}>
                                              <b>Setor:</b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.sector.sector}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={event.scheduleConfirmations.attendanceRequest.format ===
                                              "presential"
                                              ? `Local da atividade ${event.scheduleConfirmations.attendanceRequest.address}`
                                              : `Link da atividade ${event.scheduleConfirmations.attendanceRequest.address}`}>
                                              <b>
                                                {event.scheduleConfirmations.attendanceRequest.format ===
                                                  "presential"
                                                  ? "Local da atividade"
                                                  : "Link da atividade"}
                                              </b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.address}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={event.scheduleConfirmations.attendanceRequest.musicalPresentation
                                              ? "Haverá apresentação musical"
                                              : "Não haverá apresentação musical"}>
                                              <b>Apresentação musical:</b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.musicalPresentation
                                                ? "Sim"
                                                : "Não"}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={event.scheduleConfirmations.attendanceRequest.musicalPresentation
                                              ? "Haverá hino nacional"
                                              : "Não haverá hino nancional"}>
                                              <b>Hino nacional:</b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.nationalAnthem
                                                ? "Sim"
                                                : "Não"}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={`Repetição ${repeat[event.scheduleConfirmations.schedule.repeat]}`}>
                                              <b>Repetição:</b>{" "}
                                              {repeat[event.scheduleConfirmations.schedule.repeat]}
                                            </h5>
                                            <h5 aria-level="0" role="presentation" aria-label={`Observações ${event.scheduleConfirmations.attendanceRequest.additionalRemarks}`}>
                                              <b>Observações:</b>{" "}
                                              {event.scheduleConfirmations.attendanceRequest.additionalRemarks}
                                            </h5>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="button"
                                              className="btn btn-light me-2"
                                              onClick={this.toggle}
                                            >
                                              Fechar
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </ModalBody>
                              </Modal>
                            </Col>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Appointments.propTypes = {
  className: PropTypes.string,
  interpreters: PropTypes.array,
  events: PropTypes.array,
  onGetEvents: PropTypes.func,
  onGetInterpreters: PropTypes.func,
  event: PropTypes.object,
};

const mapStateToProps = ({ calendar, shared }) => ({
  events: calendar.events,
  event: calendar.event,
  interpreters: shared.interpreters,
});

const mapDispatchToProps = dispatch => ({
  onGetEvents: (dateIntervalStart, dateIntervalEnd) =>
    dispatch(
      getEventsCalendar({
        dateIntervalStart,
        dateIntervalEnd,
      })
    ),
  onGetMeetingDetails: (meetingId, userId) =>
    dispatch(getMeetingDetailsAdmin({ meetingId, userId })),
  onCloseModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
