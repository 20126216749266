import { api } from "../../services/api";
import { processErrorMessage } from "../../helpers/errors";
import {
  GET_EXPORT_SUCCESS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAIL,
  API_ERROR,
  GET_REQUESTS_LOADING,
} from "./actionTypes";
import { getDateStr } from "../../helpers/date";

export const getCanceledRequests =
  (page = 1, startDate = "", endDate = "") =>
  dispatch => {
    dispatch({ type: GET_REQUESTS_LOADING });
    api
      .get(
        `attendance_requests/allRejected?startDate=${startDate}&endDate=${endDate}&page=${page}`,
        { withCredentials: true }
      )
      .then(response => {
        dispatch({
          type: GET_REQUESTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch(error => {
        dispatch(getCanceledRequestsFail(error));
        processErrorMessage(dispatch, error, API_ERROR);
      });
  };

export const exportCanceledRequests =
  (page = 1, startDate = "", endDate = "") =>
  dispatch => {
    dispatch({ type: GET_REQUESTS_LOADING });
    api
      .get(
        `attendance_requests/exportReportingRejecteds?startDate=${startDate}&endDate=${endDate}`,
        {
          withCredentials: true,
          responseType: "blob",
        }
      )
      .then(response => {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `solicitacoes_rejeitadas_${getDateStr()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch({
          type: GET_EXPORT_SUCCESS,
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: API_ERROR,
          payload: error.response?.data,
        });
      })
      .finally(() => {
        dispatch({
          type: GET_EXPORT_SUCCESS,
        });
      });
  };

export const getCanceledRequestsSuccess = users => ({
  type: GET_REQUESTS_SUCCESS,
  payload: users,
});

export const getCanceledRequestsFail = error => ({
  type: GET_REQUESTS_FAIL,
  payload: error,
});

export const apiReqCanceledError = error => ({
  type: API_ERROR,
  payload: error,
});
