import React, { Component } from "react";
import { Badge, Table, Row, Col, Card, CardBody, Input, Form } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getRequests, sendDataToConfirmRequest, cancelRequest, getAllRequests } from "../../store/actions";
import { ROLE_ADMIN } from "../../constants/roles"
import moment from 'moment';
import {
  APPROVED,
  CANCELED_BY_REQUESTER,
  PARTIALLY_APPROVED,
  REJECTED,
  CREATED
} from 'constants/roles'

const statusNames = {
  [APPROVED]: 'Aprovada',
  [CANCELED_BY_REQUESTER]: 'Cancelada pelo solicitante',
  [PARTIALLY_APPROVED]: 'Parcialmente aprovada',
  [REJECTED]: 'Rejeitada',
  [CREATED]: 'Criada',
}

const statusColor = {
  [APPROVED]: "bg-success " + " font-size-11",
  [CANCELED_BY_REQUESTER]: 'bg-danger' + " font-size-11",
  [PARTIALLY_APPROVED]: 'bg-info' + " font-size-11",
  [REJECTED]: 'bg-danger' + " font-size-11",
  [CREATED]: "bg-warning " + " font-size-11",
}

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import CancelAttendanceModal from "components/Common/CancelAttendance";
import InformationMailModal from "components/Common/InformationMailModal";


class MyRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      currentPage: props.currentPage,
      activity: "",
      pages: [],
      total: 0,
      cancelModal: false,
      selectedRequest: {},
      informationModal: false,
    };


  }

  componentDidMount() {
    this.props.role === ROLE_ADMIN ? this.props.getAllRequests("", 1) :
      this.props.getRequests("", 1);   
  }
  componentDidUpdate(prevProps, prevState) {
    const { page: currentPage, totalCount } = this.props
    if (prevProps !== this.props) {
      const { error } = this.props;
      const { attendance } = this.props.requestList
      const totalPages = Math.ceil(totalCount / 10)
      const arrayPages = []
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i)
      }
      this.setState({ dados: attendance, currentPage, pages: arrayPages, total: totalCount, error });
    }
    if (prevState.activity !== this.state.activity) {
      this.props.role === ROLE_ADMIN ? this.props.getAllRequests(this.state.activity, 1) :
        this.props.getRequests(this.state.activity || '', 1);
    }
    if (prevProps.page !== this.props.page) {
      this.props.role === ROLE_ADMIN ? this.props.getAllRequests(this.state.activity, this.props.page) :
        this.props.getRequests(this.state.activity || '', this.props.page);
    }
    if (prevState.currentPage !== this.state.currentPage) {
      this.props.role === ROLE_ADMIN ? this.props.getAllRequests(this.state.activity, this.state.currentPage) :
        this.props.getRequests(this.state.activity || '', this.state.currentPage);
    }
  }
  
  toggleDisableModal() {
    this.setState(prevState => ({
      cancelModal: !prevState.cancelModal,
    }));
  };

  render() {

    const { totalCount, role } = this.props;
    //meta title
    document.title = "Solicitações | Atendimento NUPI";
    return (
      <React.Fragment>
        <CancelAttendanceModal
          show={this.state.cancelModal}
          requestName={this.state.selectedRequest.eventName}
          onDeleteClick={() => {
            this.props.cancelRequest(this.state.selectedRequest.id)
            this.setState({ cancelModal: false, selectedRequest: {}, informationModal: true })
            if (this.props.role === ROLE_ADMIN) {
              this.props.getAllRequests(this.state.activity, this.state.currentPage)
            } else {
              this.props.getRequests(this.state.activity || '', this.state.currentPage)
            }
          }

          }
          onCloseClick={() => this.setState({ cancelModal: false, selectedRequest: {} })}
        />
        <div className="page-content">
          <div className="container-fluid">
            {role === ROLE_ADMIN ? <Breadcrumbs title="Tables" breadcrumbItem="Solicitações" /> :
              <Breadcrumbs title="Tables" breadcrumbItem="Minhas solicitações" />}
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <h4 className="card-title">Solicitações</h4>
                    <Row className="d-flex flex-row justify-content-between mt-4">
                      <Col md={6} className="mb-4">
                        <Link to="/new-request" className="btn btn-success me-2">
                          Solicitar atendimento
                        </Link>
                      </Col>
                      <Col md={6} className="mb-4">
                        <Form onSubmit={e => {
                          e.preventDefault()
                          this.props.role === ROLE_ADMIN ? this.props.getAllRequests(this.state.activity, 1) :
                            this.props.getRequests(this.state.activity || '', 1)
                        }
                        }>
                          <Input placeholder="Buscar solicitações" onChange={e => this.setState({ activity: e.target.value })}>
                          </Input>
                        </Form>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <Table className="align-middle mb-0">
                        <thead>
                          <tr>
                            <th>Solicitante</th>
                            <th>Título</th>
                            <th>Data de criação</th>
                            <th>Data da última atualização</th>
                            <th>Status</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dados != undefined &&
                            this.state.dados.map != undefined ? (
                            this.state.dados.map((item, index) => {
                              return (
                                <tr key={index} tabIndex="0">
                                  <td aria-label={'Nome do solicitante'+ item.creator.name}>{item.creator.name}</td>
                                  <td aria-label={'Nome do evento'+ item.eventName}>{item.eventName}</td>
                                  <td aria-label={'Data de criação'+ moment(item.createdAt).format('DD/MM/YYYY')}>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                                  <td aria-label={'Data da última atualização'+ moment(item.updatedAt).format('DD/MM/YYYY')}>{moment(item.updatedAt).format('DD/MM/YYYY')}</td>
                                  <td aria-label={'A solicitação tem Status ' + statusNames[item.requestStatusId]}>
                                    <Badge
                                      className={statusColor[item.requestStatusId]}
                                    >
                                      {statusNames[item.requestStatusId]}
                                    </Badge>
                                  </td>
                                  <td className="">
                                    {role !== ROLE_ADMIN ? (
                                      <>
                                        <button
                                          type="button"
                                          className="btn btn-light btn-sm"
                                          aria-label={'Visualizar solicitação'+ item.eventName}
                                          style={{ marginRight: 8 }}
                                          onClick={() => {
                                            this.props.history.push(`view-request/${item.id}`)
                                          }}
                                        >
                                          Visualizar
                                        </button>

                                        {item.requestStatusId==1 && <button
                                          type="button"
                                          aria-label={'Cancelarar solicitação'+ item.eventName}
                                          className="btn btn-danger btn-sm"
                                          onClick={(e) => {
                                            this.setState({
                                              selectedRequest: item,
                                              cancelModal: true,
                                            })

                                          }}

                                        >
                                          Cancelar
                                        </button>
                                        }
                                      </>
                                    ) : (<>

                                    {item.requestStatusId==1 ? (<Link
                                        to={`/request-analysis/${item.id}`}
                                        className="btn btn-primary btn-sm"
                                        aria-label={role == ROLE_ADMIN?'Analisar solicitação'+ item.eventName + 'do solicitante' + item.creator.name:'Visualizar solicitação'+ item.eventName}
                                        style={{ marginRight: 8, paddingRight: 12, paddingLeft: 12 }}
                                      >
                                        Analisar
                                      </Link>) : (<button
                                          type="button"
                                          className="btn btn-light btn-sm"
                                          style={{ marginRight: 8 }}
                                          aria-label={role == ROLE_ADMIN?'Visualizar solicitação'+ item.eventName + 'do solicitante' + item.creator.name:'Visualizar solicitação'+ item.eventName}
                                          onClick={() => {
                                            this.props.history.push(`view-request/${item.id}`)
                                          }}

                                        >
                                          Visualizar
                                        </button>)
                                    
                                    }
                                      
                                    </>)}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <th scope="row"></th>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div className='d-flex flex-row-reverse bd-highlight'>
                        <div className='p-2 bd-highlight'>
                          {totalCount > 10 ? this.state.pages.map(page => (
                            <button
                              disabled={page === this.state.currentPage}
                              key={page}
                              onClick={() => {
                                this.setState({ currentPage: page })
                              }}
                              className='btn btn-primary mx-1 rounded-circle'
                            >
                              {page}
                            </button>
                          )) : ''}
                        </div>
                      </div>

                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentPage: state.Request.page,
  role: state.User.userData && state.User.userData.role ? state.User.userData.role : '',
  error: state.Request.error,
  loading: state.Request.loading,
  data: state.Request.data,
  requestList: state.Request.requestList,
  pageCount: state.Request.pageCount,
  page: state.Request.page,
  totalCount: state.Request.totalCount,
  error: state.Request.error
});


export default withRouter(
  connect(mapStateToProps, { getRequests, sendDataToConfirmRequest, cancelRequest, getAllRequests })(MyRequests)
);
