import { api } from "../../services/api";
import { processErrorMessage } from "../../helpers/errors";
import {
  GET_STAKEHOLDER_TYPES_LOADING,
  GET_STAKEHOLDER_TYPES_SUCCESS,
  GET_INTERPRETERS_SUCCESS,
  API_ERROR,
  GET_SECTOR_LIST_LOADING,
  GET_SECTOR_LIST_SUCCESS,
  GET_PARTIAL_SCEDULES_LIST_LOADING,
  GET_PARTIAL_SCEDULES_LIST_SUCCESS
} from "./actionTypes";

export const getStakeholderTypes = () => (dispatch) => {
  dispatch({ type: GET_STAKEHOLDER_TYPES_LOADING })
  api.get('stakeholder_types/all')
    .then(response => {
      dispatch({
        type: GET_STAKEHOLDER_TYPES_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const getInterpreters = (requestId) => (dispatch) => {
  api.get(`interpreter/all/${requestId}`)
    .then(response => {
      dispatch({
        type: GET_INTERPRETERS_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const getScedulesList = (id) => (dispatch) => {
  dispatch({ type: GET_PARTIAL_SCEDULES_LIST_LOADING })
  api.get('schedules_confirmation/list_by/'+id)
    .then(response => {
      dispatch({
        type: GET_PARTIAL_SCEDULES_LIST_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}

export const getSectorList = () => (dispatch) => {
  dispatch({ type: GET_SECTOR_LIST_LOADING })
  api.get('sectors')
    .then(response => {
      dispatch({
        type: GET_SECTOR_LIST_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}