import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ConfirmRequest from "./NewRequestTabs/ConfirmRequest";
import classnames from "classnames";
import {
  getRequestById,
  cancelRequest,
  getRequests,
} from "../../store/actions";
import { connect } from "react-redux";
import CancelAttendanceModal from "components/Common/CancelAttendance";
import InformationMailModal from "components/Common/InformationMailModal";

class RequestAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelModal: false,
      selectedRequest: {},
      informationModal: false,
      id: "",
    };
  }

  componentDidMount() {
    const id = this.props.location.pathname.split("/")[2];
    this.setState({ id });
    this.props.getRequestById(id);
  }

  render() {
    document.title = "Minhas solicitações | Atendimento NUPI";
    return (
      <React.Fragment>
        <CancelAttendanceModal
          show={this.state.cancelModal}
          requestName={this.props.data.eventName}
          onDeleteClick={() => {
            this.props.cancelRequest(this.state.id);
            this.setState({ cancelModal: false, informationModal: true });
            this.props.getRequests("", 1);
          }}
          onCloseClick={() =>
            this.setState({ cancelModal: false, selectedRequest: {} })
          }
        />

        <InformationMailModal
          show={this.state.informationModal}
          onCloseClick={() => {
            this.setState({ informationModal: false });
            this.props.history.push('/my-requests');
          }}
          text={"Você receberá um e-mail com a confirmação deste cancelamento!"}
          iconClass={"mdi mdi-email-outline"}
        />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Tables" breadcrumbItem="Solicitações" />

            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Analisar Solicitação</h4>
                  <div className="wizard clearfix">
                    <div className="content clearfix">
                      <TabContent activeTab={1} className="body">
                        <TabPane tabId={1}>
                          {this.props.data != undefined ? (
                            <ConfirmRequest />
                          ) : (
                            <span>loading </span>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <Button
                          className="btn btn-primary"
                          onClick={e => {
                            this.props.history.push("/my-requests");
                          }}
                        >
                          Voltar
                        </Button>
                      </div>
                      <div className="d-flex flex-row gap-3">

                        <Link
                          to={`/confirm-request/${this.state.id}`}
                          className="btn btn-success"
                        >
                          Aceitar solicitação
                        </Link>
                       
                        <Link
                          to={`/reject-request/${this.state.id}`}
                          className="btn btn-danger"
                        >
                          Rejeitar
                       
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { error, loading, data, requestList } = state.Request;
  return { error, loading, data, requestList };
};

export default withRouter(
  connect(mapStateToProps, { getRequestById, cancelRequest, getRequests })(
    RequestAnalysis
  )
);
