import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="6">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Olá {this.props.data.name.split(' ')[0]}</h5>
                  <p>Bem-vindo!</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="12">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      <h5 className="font-size-15">{this.props.data.totalInterpreters}</h5>
                      <p className="text-muted mb-0">{
                        this.props.data.totalInterpreters > 1  ? "Intérpretes" : "Intérprete"
                      }</p>
                    </Col>
                    <Col xs="6">
                      <h5 className="font-size-15">{this.props.data.totalSchedules}</h5>
                      <p className="text-muted mb-0">Compromissos em {new Date().getFullYear()}</p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link
                      to="/appointments"
                      className="btn btn-primary btn-sm"
                    >
                      Ver agendas {" "}<i className="mdi mdi-arrow-right ms-1"/>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default WelcomeComp