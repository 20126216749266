export const SEND_EVENT_INFORMATION = "SEND_EVENT_INFORMATION";
export const SEND_SCHEDULES = "SEND_SCHEDULES";
export const SEND_DATA_TO_CONFIRM = "SEND_DATA_TO_CONFIRM";
export const SUBMIT_REQUEST_SUCCESS = "SUBMIT_REQUEST_SUCCESS";
export const SUBMIT_REQUEST_ERROR = "SUBMIT_REQUEST_ERROR";
export const SEND_REQUEST_DATA = "SEND_REQUEST_DATA"
export const GET_REQUESTS_DATA = "GET_REQUESTS_DATA"
export const GET_REQUEST_DATA = "GET_REQUEST_DATA"
export const DELETE_REQUEST_RESPONSE = "DELETE_REQUEST_RESPONSE"
export const TOGGLE_SAVE_REQUEST = "TOGGLE_SAVE_REQUEST"
export const SEND_REJECT_REQUEST = "SEND_REJECT_REQUEST"
export const TOGGLE_LOADING = "TOGGLE_LOADING"
export const GET_REQUEST_MEEETING_DATA = "GET_REQUEST_MEEETING_DATA"
export const GET_MEETING_DETAILS = "GET_MEETING_DETAILS"
export const CLOSE_MODAL = "CLOSE_MODAL"