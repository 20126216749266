import {
  GET_STAKEHOLDER_TYPES_LOADING,
  GET_STAKEHOLDER_TYPES_SUCCESS,
  GET_INTERPRETERS_LOADING,
  GET_INTERPRETERS_SUCCESS,
  API_ERROR,
  GET_PARTIAL_SCEDULES_LIST_LOADING,
  GET_PARTIAL_SCEDULES_LIST_SUCCESS,
  GET_SECTOR_LIST_LOADING,
  GET_SECTOR_LIST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  stakeholderTypes: [],
  interpreters: [],
  partialSchedules: [],
  error: "",
  sectors: [],
  loading: false,
}

const shared = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAKEHOLDER_TYPES_LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_STAKEHOLDER_TYPES_SUCCESS:
      return {
        ...state,
        stakeholderTypes: action.payload,
        loading: false
      }
    case GET_INTERPRETERS_LOADING:
      return {
        ...state,
        loading: true
      }
    case GET_INTERPRETERS_SUCCESS:
      return {
        ...state,
        interpreters: action.payload,
        loading: false
      }

    case GET_PARTIAL_SCEDULES_LIST_LOADING:
      return {
        ...state,
        loading: true
      }
    
    case GET_PARTIAL_SCEDULES_LIST_SUCCESS:
      return {
        ...state,
        partialSchedules: action.payload,
        loading: false
      }
    
    case GET_SECTOR_LIST_LOADING:
      return {
        ...state,
        loading: true
      }
    
    case GET_SECTOR_LIST_SUCCESS:
      return {
        ...state,
        sectors: action.payload,
        loading: false
      }
    
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export default shared
